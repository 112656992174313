export interface TsAppVersion {
    version: string;
    name: string;
    description?: string;
    versionLong?: string;
    versionDate: string;
    gitCommitHash?: string;
    gitCommitDate?: string;
    gitTag?: string;
};
export const versions: TsAppVersion = {
    version: '35.0.0',
    name: 'lingo2-web',
    versionDate: '2024-09-15T16:48:41.801Z',
    gitCommitHash: '5f208f1',
    versionLong: '35.0.0-5f208f1',
};
export default versions;
