import { Injectable, NgZone } from '@angular/core';
import { PlatformService } from '@core/services/platform.service';
import { DestroyableComponent } from '@models/destroyable.component';
import { DeviceDetectorService } from 'ngx-device-detector';
import { BehaviorSubject, fromEvent, Observable, ReplaySubject } from 'rxjs';
import { debounceTime, distinctUntilChanged, map, takeUntil } from 'rxjs/operators';

export interface IScreen {
  availWidth: number;
  availHeight: number;
  width: number;
  height: number;
  isPortrait: boolean;
  isLandscape: boolean;
}

export enum EIPhones {
  XII = 'XII',
  XI_Pro_Max_14 = 'XI_Pro_Max_14',
  XI_Pro_Max = 'XI_Pro_Max',
  XI_Pro = 'XI_Pro',
  XI = 'XI',
  XI_14 = 'XI_14',
  XR = 'XR',
  XS_Max = 'XS_Max',
  XS = 'XS',
  XS_13 = 'XS_13',
  X = 'X',
}

export interface IIPhonesProperties {
  logicalWidth: number;
  logicalHeight: number;
  innerHeightP?: number;
  innerHeightL?: number;
  innerHeightPExpand?: number;
  innerHeightLExpand?: number;
  devicePixelRatio?: number;
  osVersion?: number;
}

const devices: { [key: string]: IIPhonesProperties } = {
  [EIPhones.XII]: {
    logicalWidth: 390,
    logicalHeight: 844,
    innerHeightP: 778,
    innerHeightL: 390,
    innerHeightPExpand: 664,
    innerHeightLExpand: 340,
    devicePixelRatio: 3,
    osVersion: 14,
  },
  [EIPhones.XI_Pro_Max_14]: {
    logicalWidth: 414,
    logicalHeight: 896,
    innerHeightP: 833,
    innerHeightL: 414,
    innerHeightPExpand: 719,
    innerHeightLExpand: 364,
    devicePixelRatio: 3,
    osVersion: 14,
  },
  [EIPhones.XI_Pro_Max]: {
    logicalWidth: 414,
    logicalHeight: 896,
    innerHeightP: 832,
    innerHeightL: 414,
    innerHeightPExpand: 719,
    innerHeightLExpand: 364,
    devicePixelRatio: 3,
    osVersion: 13,
  },
  [EIPhones.XI_Pro]: {
    logicalWidth: 375,
    logicalHeight: 812,
    innerHeightP: 749,
    innerHeightL: 375,
    innerHeightPExpand: 635,
    innerHeightLExpand: 325,
    devicePixelRatio: 3,
    osVersion: 13,
  },
  [EIPhones.XI]: {
    logicalWidth: 414,
    logicalHeight: 896,
    innerHeightP: 833,
    innerHeightL: 414,
    innerHeightPExpand: 719,
    innerHeightLExpand: 364,
    devicePixelRatio: 2,
    osVersion: 13,
  },
  [EIPhones.XI_14]: {
    logicalWidth: 414,
    logicalHeight: 896,
    innerHeightP: 829,
    innerHeightL: 414,
    innerHeightPExpand: 715,
    innerHeightLExpand: 364,
    devicePixelRatio: 2,
    osVersion: 14,
  },
  [EIPhones.XR]: {
    logicalWidth: 414,
    logicalHeight: 896,
    innerHeightP: 833,
    innerHeightL: 414,
    innerHeightPExpand: 719,
    innerHeightLExpand: 364,
    devicePixelRatio: 2,
    osVersion: 12,
  },
  [EIPhones.XS_Max]: {
    logicalWidth: 414,
    logicalHeight: 896,
    innerHeightP: 832,
    innerHeightL: 414,
    innerHeightPExpand: 719,
    innerHeightLExpand: 364,
    devicePixelRatio: 3,
    osVersion: 12,
  },
  [EIPhones.XS]: {
    logicalWidth: 375,
    logicalHeight: 812,
    innerHeightP: 748,
    innerHeightL: 375,
    innerHeightPExpand: 635,
    innerHeightLExpand: 325,
    devicePixelRatio: 3,
    osVersion: 12,
  },
  [EIPhones.XS_13]: {
    logicalWidth: 375,
    logicalHeight: 812,
    innerHeightP: 749,
    innerHeightL: 375,
    innerHeightPExpand: 635,
    innerHeightLExpand: 325,
    devicePixelRatio: 3,
    osVersion: 13,
  },
  [EIPhones.X]: {
    logicalWidth: 375,
    logicalHeight: 812,
    innerHeightP: 748,
    innerHeightL: 375,
    innerHeightPExpand: 635,
    innerHeightLExpand: 325,
    devicePixelRatio: 3,
    osVersion: 11,
  },
};

export enum DeviceOrientationEnum {
  portrait = 'portrait',
  landscape = 'landscape',
}

@Injectable({
  providedIn: 'root',
})
export class ScreenService extends DestroyableComponent {
  private scrollTopSubject = this.register(new BehaviorSubject<number>(0));
  public scrollTop$ = this.scrollTopSubject.asObservable();

  private widthSubject = this.register(new BehaviorSubject<number>(375));
  public width$ = this.widthSubject.asObservable();

  private heightSubject = this.register(new BehaviorSubject<number>(812));
  public height$ = this.heightSubject.asObservable();

  public isSmallScreen = true;
  public isWideScreen = false;

  private _width: Observable<any>;
  private _height: Observable<any>;
  private _scrollTop: Observable<any>;

  private subject = this.register(new ReplaySubject<IScreen>(1));
  public options = this.subject.asObservable();

  private _iPhoneScreenOptions = this.register(new ReplaySubject<IScreen>(1));
  public iPhoneScreenOptions$ = this._iPhoneScreenOptions.asObservable();

  private static screenOptions(): IScreen {
    return {
      availWidth: window.screen.availWidth,
      availHeight: window.screen.availHeight,
      width: window.screen.width,
      height: window.screen.height,
      isPortrait: window.orientation ? window.orientation === 0 : true,
      isLandscape: window.orientation ? window.orientation === 90 : false,
    };
  }

  private extraFontsEnabled = false;

  constructor(public deviceService: DeviceDetectorService, private zone: NgZone, protected platform: PlatformService) {
    super(platform);
    this.init();
  }

  protected init() {
    if (this.isBrowser) {
      this._scrollTop = fromEvent(window, 'scroll').pipe(
        map((e) => {
          const supportPageOffset = window.pageXOffset !== undefined;
          const isCSS1Compat = (document.compatMode || '') === 'CSS1Compat';
          // var x = supportPageOffset ? window.pageXOffset : isCSS1Compat ? document.documentElement.scrollLeft : document.body.scrollLeft;
          return supportPageOffset
            ? window.pageYOffset
            : isCSS1Compat
            ? document.documentElement.scrollTop
            : document.body.scrollTop;
        }),
        debounceTime(10),
        distinctUntilChanged(),
      );
      this._width = fromEvent(window, 'resize').pipe(
        map((e) => window.innerWidth),
        debounceTime(250),
        distinctUntilChanged(),
      );
      this._height = fromEvent(window, 'resize').pipe(
        map((e) => window.innerHeight),
        debounceTime(250),
        distinctUntilChanged(),
      );

      this._scrollTop.pipe(takeUntil(this.destroyed$)).subscribe((scrollTop) => {
        this.scrollTopSubject.next(scrollTop);
      });
      this._width.pipe(takeUntil(this.destroyed$)).subscribe((width) => {
        this.onWidthChange(width);
      });
      this._height.pipe(takeUntil(this.destroyed$)).subscribe((height) => {
        this.onHeightChange(height);
      });

      this.onWidthChange(window.innerWidth);
      this.onHeightChange(window.innerHeight);

      fromEvent(window, 'resize')
        .pipe(debounceTime(500), distinctUntilChanged())
        .pipe(takeUntil(this.destroyed$))
        .subscribe((event) => {
          this.subject.next(ScreenService.screenOptions());
        });

      this.subject.next(ScreenService.screenOptions());
    }
  }

  protected onWidthChange(width) {
    this.isSmallScreen = /* this.deviceService.isMobile() || this.deviceService.isTablet() ||*/ width <= 990;
    this.isWideScreen = /* this.deviceService.isDesktop() &&*/ width >= 1205;
    this.widthSubject.next(width);
  }

  protected onHeightChange(height) {
    this.heightSubject.next(height);
  }

  public setBodyFixed(state: boolean) {
    this.setBodyClass('fixed', state);
    if (!this.deviceService.isDesktop()) {
      this.setBodyClass('mobile', state);
    }

    /**
     * Дополнительная проверка удалился ли класс после закрытия модалки
     */
    if (!document.getElementsByTagName('app-modal').length && document.body.classList.contains('fixed')) {
      document.body.classList.remove('fixed');
    }
  }

  public setBodyClass(className: string, state: boolean) {
    if (this.isBrowser) {
      this.zone.runOutsideAngular(() => {
        const body = document.body;
        if (state) {
          if (!body.classList.contains(className)) {
            body.classList.add(className);
          }
        } else {
          if (body.classList.contains(className)) {
            body.classList.remove(className);
          }
        }
      });
    }
  }

  public setContainerClass(id: string, className: string, state: boolean) {
    if (this.isBrowser) {
      this.zone.runOutsideAngular(() => {
        const element = document.getElementById(id);
        if (element) {
          if (state) {
            element.classList.add(className);
          } else {
            element.classList.remove(className);
          }
        }
      });
    }
  }

  public enableExtraFonts() {
    if (this.isBrowser && !this.extraFontsEnabled) {
      this.extraFontsEnabled = true;
      this.zone.runOutsideAngular(() => {
        const link = document.createElement('link');
        link.href = 'https://fonts.googleapis.com/css?family=Londrina+Shadow&display=swap';
        link.rel = 'stylesheet';
        document.getElementsByTagName('head')[0].appendChild(link);
      });
    }
  }

  public iPhoneModels() {
    if (this.deviceService.isMobile() && this.isIPhone) {
      const keys = Object.keys(devices);
      return keys
        .filter(
          (key) =>
            (devices[key].logicalWidth === window.screen.width &&
              devices[key].logicalHeight === window.screen.height) ||
            (devices[key].logicalWidth === window.screen.height && devices[key].logicalHeight === window.screen.width),
        )
        .filter((key) => devices[key].osVersion === this.osVersion)
        .filter((key) => devices[key].devicePixelRatio === this.devicePixelRatio);
    }
    return [];
  }

  public get isIPhone(): boolean {
    return !!navigator.userAgent.match(/iPhone/i);
  }

  public get isSafari(): boolean {
    return (
      navigator.vendor &&
      navigator.vendor.indexOf('Apple') > -1 &&
      navigator.userAgent &&
      navigator.userAgent.indexOf('CriOS') === -1 &&
      navigator.userAgent.indexOf('FxiOS') === -1
    );
  }

  public get isIPhoneXAndYoung(): boolean {
    if (this.deviceService.isMobile() && this.isIPhone) {
      return !!this.iPhoneModels().length;
    }
    return false;
  }

  public get osVersion(): number {
    if (!this.isIPhone) {
      return null;
    }
    const v = navigator.appVersion.match(/OS (\d+)_(\d+)_?(\d+)?/);
    return parseInt(v[1], 10);
  }

  public get devicePixelRatio(): number {
    return window.devicePixelRatio;
  }

  public get toolsExpanded(): boolean {
    const devicesList = this.iPhoneModels().map((key) =>
      ScreenService.screenOptions().isPortrait
        ? devices[key].innerHeightPExpand === window.innerHeight
        : devices[key].innerHeightLExpand === window.innerHeight,
    );
    return devicesList.includes(true);
  }

  public get deviceOrientation() {
    if (screen.availHeight > screen.availWidth) {
      return DeviceOrientationEnum.portrait;
    } else {
      return DeviceOrientationEnum.landscape;
    }
  }
}
